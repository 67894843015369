<template>
  <div>
    <borderBox label="Invoice">
      <v-form ref="create" style="padding: 30px 10px;">
        <div class="row">
          <div class="col-md-6" style="background-color: #fff">
            <v-autocomplete
              v-bind="basic"
              label="Select Customer"
              height="105"
              :items="customers"
              item-text="full_name"
              item-value="id"
              v-model="form.customer_id"
              :rules="[util.validation.required]"
            >
              <template #selection="{ item }">
                <v-card elevation="0" style="width: 100%">
                  <b><v-icon>mdi-account</v-icon></b>
                  {{ item.full_name }} <br />
                  <b><v-icon>mdi-map-marker</v-icon></b>
                  {{ item.address }} <br />
                  <b><v-icon>mdi-email</v-icon></b>
                  {{ item.email }} <br />
                </v-card>
              </template>
              <template #append-item>
                <v-row>
                  <v-col>
                    <v-item>
                      <v-btn block v-on:click="showAddCustomerDialog()"
                        >Add New Customer</v-btn
                      >
                    </v-item>
                  </v-col>
                </v-row>
              </template>
            </v-autocomplete>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-6">
                <v-text-field
                  label="Reference Number"
                  placeholder=""
                  v-bind="basic"
                  v-model="form.ref_no"
                  :rules="[util.validation.required]"
                  disabled
                ></v-text-field>
              </div>
              <div class="col-md-6">
                <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  max-width="290"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="form.issue_date"
                      clearable
                      label="Issue Date"
                      readonly
                      v-bind="{ ...basic, ...attrs }"
                      v-on="on"
                      @click:clear="form.issue_date = null"
                      :rules="[util.validation.required]"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.issue_date"
                    @change="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  max-width="290"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="form.valid_till"
                      clearable
                      label="Valid Till"
                      readonly
                      v-bind="{ ...basic, ...attrs }"
                      v-on="on"
                      @click:clear="form.valid_till = null"
                      :rules="[util.validation.required]"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.valid_till"
                    @change="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </div>
              <div class="col-md-6">
                <v-text-field
                  v-if="$store.state.basicSetUpData.logged_in_user"
                  v-bind="basic"
                  label="Issued By"
                  :value="$store.state.basicSetUpData.logged_in_user.first_name"
                  placeholder=""
                  disabled
                  v-model="form.prepared_by"
                  :rules="[util.validation.required]"
                ></v-text-field>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <v-simple-table class="table">
              <thead class="thead">
                <tr>
                  <th>Sn</th>
                  <th>Item Description</th>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in form.items" :key="item._id + 'a'">
                  <td>{{ i + 1 }}</td>
                  <td>
                    <v-autocomplete
                      v-bind="basic"
                      :items="products"
                      item-text="name"
                      item-value="id"
                      label="Select Product"
                      v-model="item.product_id"
                      :rules="[util.validation.required]"
                    ></v-autocomplete>
                  </td>
                  <td>
                    <v-text-field
                      v-bind="basic"
                      label="Quantity"
                      type="number"
                      v-model="item.quantity"
                      :rules="[util.validation.required]"
                    ></v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      v-bind="basic"
                      label="Price"
                      type="number"
                      v-model="item.price"
                      :rules="[util.validation.required]"
                    ></v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      v-bind="basic"
                      label="Total"
                      type="number"
                      :value="item.quantity * item.price"
                      disabled
                    ></v-text-field>
                  </td>
                  <td>
                    <v-icon v-if="i != 0" @click="removeItem(item)" color="#ff0000"
                      >mdi-delete</v-icon
                    >
                  </td>
                </tr>
                <tr>
                  <td colspan="5">
                    <v-btn class="add_new_item" @click="addItem">
                      Add New Item
                    </v-btn>
                  </td>
                </tr>
                <tr>
                  <td colspan="3" :rowspan="rowspan">
                    <v-textarea
                      v-bind="basic"
                      label="Notes"
                      placeholder="Notes"
                      v-model="form.notes"
                    />
                    <br />
                    <p>Invoice Templates *</p>

                    <templateChoser
                      v-model="form.template_id"
                      :template_id="form.template_id"
                    />
                    <!-- <v-btn
                      color="#1d2b58"
                      style="color: white; margin-left: 10px"
                      >Files<v-icon>mdi-file</v-icon>
                    </v-btn> -->
                    <fileChoser @fileUploaded="fileUploaded" :f="form.files" />
                  </td>
                  <td class="text-right">SubTotal</td>
                  <td>
                    <v-text-field v-bind="basic" disabled :value="subTotal" />
                  </td>
                </tr>
                <tr>
                  <td class="text-right">Discount</td>
                  <td>
                    <v-text-field v-bind="basic" v-model="form.discount" />
                  </td>
                </tr>
                <tr v-for="(tax, i) in form.taxes" :key="'a' + tax._id">
                  <td class="text-right">
                    <template>
                      <v-icon
                        style="cursor: pointer"
                        title="Delete"
                        @click="removeTax(tax)"
                        v-if="i != 0"
                        >mdi-delete</v-icon
                      >
                      <v-icon
                        style="cursor: pointer"
                        title="Add"
                        @click="addTax"
                        >mdi-plus</v-icon
                      >
                    </template>
                    <v-autocomplete
                      style="width: 70%; display: inline-block"
                      v-bind="basic"
                      label="Select Tax"
                      :items="taxs"
                      item-text="name"
                      item-value="id"
                      v-model="tax.id"
                      :rules="[util.validation.required]"
                    ></v-autocomplete>
                  </td>
                  <td>
                    <v-text-field
                      v-bind="basic"
                      disabled
                      :value="getTaxAmount(tax)"
                    />
                  </td>
                </tr>
                <tr>
                  <td class="text-right">Grand Total</td>
                  <td>
                    <v-text-field v-bind="basic" disabled :value="grandTotal" />
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <v-btn
              class="save_btn"
              color="primary"
              block
              @click="save"
              :loading="loading"
              >Save</v-btn
            >
          </div>
        </div>
      </v-form>
    </borderBox>
    <v-dialog v-model="addCustomer" max-width="600px">
      <addContact @contactStored="contactStored" />
    </v-dialog>
  </div>
</template>
<script>
import util from "@/util/util";
import moment from "moment";
import borderBox from "@/components/Quotation/border-box.vue";
import templateChoser from "@/components/Quotation/template-choser.vue";
import fileChoser from "@/components/Quotation/file-choser.vue";
import addContact from "@/components/Contact/AddContact";
export default {
  components: {
    borderBox,
    templateChoser,
    fileChoser,
    addContact,
  },
  mounted() {
    if (!this.$route.params.id) {
      this.addItem();
      this.addTax();
      this.form.issue_date = moment().format("YYYY-MM-DD");
    } else {
      this.getQuotation();
    }
    this.getProducts();
    this.getTaxes();
    this.getCustomers();
  },
  data() {
    return {
      util,
      menu1: false,
      menu2: false,
      basic: util.input.basic,
      products: [],
      taxs: [],
      customers: [],
      form: {
        issue_date: "",
        discount: 0,
        items: [],
        taxes: [],
        ref_no: "auto generated",
      },
      show_template: false,
      addCustomer: false,
      loading: false,
    };
  },
  methods: {
    chooseTemplate() {
      this.show_template = true;
    },
    save() {
      this.loading = true;
      this.form.products = this.form.items;
      util
        .http({
          url: this.$route.params.id
            ? "invoice/" + this.$route.params.id
            : "invoice/add",
          method: this.$route.params.id ? "put" : "POST",
          data: { ...this.form },
        })
        .then((resp) => {
          this.loading = false;
          if (resp.data.status == 1) {
            util.notify(1, "Successful");
            this.$router.push({ name: "invoice" });
            this.$emit("reload-resource", true);
          } else {
            util.notify(0, resp.data.errors[0]);
          }
        });
    },

    getTaxAmount(tax) {
      let a = this.taxs.find((t) => t.id == tax.id);
      if (a) {
        return (this.subTotal * a.percentage) / 100;
      }
    },

    getCustomers() {
      util
        .http({
          url: "customer/get-all",
          method: "get",
        })
        .then((res) => {
          if (res.data.status == 1) {
            this.customers = res.data.data;
          }
        });
    },
    getProducts() {
      util
        .http({
          url: "product/get-all",
          method: "get",
        })
        .then((res) => {
          if (res.data.status == 1) {
            this.products = res.data.data;
          }
        });
    },
    getTaxes() {
      util
        .http({
          url: "tax/get-all",
          method: "get",
        })
        .then((res) => {
          if (res.data.status == 1) {
            this.taxs = res.data.data;
          }
        });
    },
    removeItem(item) {
      this.form.items.splice(
        this.form.items.findIndex((i) => {
          return i._id === item._id;
        }),
        1
      );
    },
    addItem() {
      let time = new Date().toString();
      let a = {
        _id: time,
        product_id: "",
        product_description: "",
        quantity: "",
        price: "",
        total: "",
      };
      this.form.items.push(a);
    },
    removeTax(tax) {
      this.form.taxes.splice(
        this.form.taxes.findIndex((i) => {
          return i._id === tax._id;
        }),
        1
      );
    },
    addTax() {
      let time = new Date().toString();
      let a = {
        _id: time,
        tax_description: "",
        tax_amount: "",
      };
      this.form.taxes.push(a);
    },
    getQuotation() {
      util
        .http({
          url: "invoice/" + this.$route.params.id,
          method: "get",
        })
        .then((res) => {
          if (res.data.status == 1) {
            let data = res.data.data;
            this.form.id = data.id;
            this.form.customer_id = data.customer_id;
            this.form.ref_no = data.ref_no;
            this.form.valid_till = data.valid_till;
            this.form.issue_date = data.issue_date;
            this.form.prepared_by = data.prepared_by;
            this.form.discount = data.discount;
            this.form.template_id = data.template_id;
            this.form.files = data.files;
            this.form.notes = data.notes;
            this.form.items = res.data.data.products.map((i) => {
              return {
                ...i,
                ...{
                  _id: i.id,
                  product_description: i.description,
                },
              };
            });
            this.form.taxes = res.data.data.tax.map((i) => {
              return {
                ...i,
                ...{ _id: i.id, description: "", id: i.tax_id },
              };
            });
          } else {
            util.notify(0, res.data.errors[0]);
          }
        });
    },
    showAddCustomerDialog() {
      this.addCustomer = true;
    },
    contactStored() {
      this.addCustomer = false;
      this.getCustomers();
    },
    fileUploaded(files) {
      this.form.file = files;
    },
  },
  computed: {
    rowspan() {
      return this.form.taxes.length + 3;
    },
    subTotal() {
      let total = 0;
      this.form.items.forEach((item) => {
        total += item.quantity * item.price;
      });
      return total;
    },
    taxTotal() {
      let total = 0;
      this.form.taxes.forEach((tax) => {
        total += this.getTaxAmount(tax);
      });
      return total;
    },
    grandTotal() {
      return this.subTotal - this.form.discount + this.taxTotal;
    },
  },
};
</script>
<style lang="scss" scoped>
$brand-primary: #1d2b58;
.thead tr {
  background-color: $brand-primary;
  th {
    color: white !important;
  }
}
tbody {
  td {
    padding: 10px !important;
  }
}
.btn-sm {
  background-color: $brand-primary;
  color: white;
}
.add_new_item {
  display: block !important;
  margin: 0 auto;
  background-color: #aaa !important;
}
.text-right {
  text-align: right;
}
.v-dialog div {
  background: #fff;
}
</style>
